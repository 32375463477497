.popup-wrap {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: rgba(0, 0, 0, 0.75);
    z-index: 2000;
    opacity: 0;
    transition: opacity .3s;
}

.popup-wrap.mounted {
    opacity: 1;
}

.popup-wrap > .popup {
    bottom: 0;
    left: calc(50% - 512px);
    width: 1024px;
    padding: 12px 8px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 12px 12px 0 0;
    position: fixed;
    overflow: hidden;
}

.popup-wrap > .popup > .popup-content {
    width: 100%;
    height: 100%;
    padding: 52px 56px 52px 56px;
    box-sizing: border-box;
    overflow-y: auto;
    position: relative;
}

.popup-wrap > .popup > .popup-content::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.popup-wrap > .popup > .popup-content::-webkit-scrollbar-track {
    padding-right: 8px;
    background: transparent;
}

.popup-wrap > .popup > .popup-content::-webkit-scrollbar-thumb {
    background: rgba(13, 12, 12, 0.1);
    border-radius: 10px;
}

.popup-wrap > .popup > .popup-content > .close {
    top: 64px;
    right: 64px;
    position: absolute;
}

.popup-wrap > .popup > .close > div {
    margin-left: 8px;
}

@media (min-width: 1440px) {
    .popup-wrap > .popup {
        top: 100px;
    }

    .popup-wrap.mounted > .popup {
        top: 72px;
    }
}

@media (max-width: 1439px) and (min-width: 834px) {
    .popup-wrap > .popup {
        top: 100px;
        left: calc(50% - 385px);
        width: 770px;
    }

    .popup-wrap.mounted > .popup {
        top: 32px;
    }
}

@media (min-width: 834px) {
    .popup-wrap > .popup {
        transition: top .3s;
    }
}

@media (max-width: 833px) {
    .popup-wrap {
        position: absolute;
    }

    .popup-wrap > .popup {
        padding: 0;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        border-radius: 0;
    }

    .popup-wrap > .popup > .popup-content {
        padding: 100px 32px 32px;
    }

    .popup-wrap > .popup > .popup-content > .close {
        top: 32px;
        left: 32px;
        right: auto;
    }
}
