.portfolio-item {
    margin-top: 24px;
    padding-left: 36px;
    box-sizing: border-box;
    position: relative;
}

.portfolio-item:before {
    top: 14px;
    left: 0;
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    background-size: cover;
}

.portfolio-item:nth-child(2n):before {
    background-image: url(./dot.png);
}

.portfolio-item:nth-child(2n + 1):before {
    background-image: url(./dot_fill.png);
}

.page-slide-title + .portfolio-item {
    margin-top: 28px;
}

.portfolio-item > header {
    color: #0D0C0C;
    font-weight: bold;
}

.portfolio-item > article {
    font-size: 14px;
}

@media (max-width: 833px) {
    .portfolio-item {
        margin-top: 16px;
    }

    .portfolio-item > header {
        font-size: 14px;
        line-height: 20px;
    }

    .portfolio-item > article {
        font-size: 14px;
    }

    .portfolio-item > article {
        font-size: 12px;
        line-height: 18px;
    }
}

