.service-card > .wrap.detail {
    opacity: 0;
    justify-content: center;
    align-items: center;
    transition: opacity .5s;
}

.service-card:hover > .wrap.normal,
.service-card.active > .wrap.normal {
    opacity: 0;
}

.service-card:hover > .wrap.detail,
.service-card.active > .wrap.detail {
    opacity: 1;
}

.service-card > .wrap.detail > .background,
.service-card > .wrap.detail > .blur {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 0;
}

.service-card > .wrap.detail > .background {
    background-size: cover;
    background-position: center;
}

.service-card > .wrap.detail > .blur {
    background-image: linear-gradient(0deg, rgba(49, 31, 255, 0.6), rgba(49, 31, 255, 0.6)), linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24));
}

.service-card > .wrap.detail h2,
.service-card > .wrap.detail h3,
.service-card > .wrap.detail p {
    color: #FFFFFF;
}

.service-card > .wrap.detail path {
    fill: #CCA63D;
    fill-opacity: 1;
}

.service-card > .wrap.detail > .title {
    width: 240px;
    z-index: 100;
    position: relative;
    transition: transform .3s;
    transition-delay: .1s;
}

.service-card > .wrap.detail > .title > .no {
    color: #CCA63D;
}

.service-card > .wrap.detail > .detail {
    z-index: 100;
    position: relative;
}

.service-card > .wrap.detail > .detail > .title {
    font-weight: bold;
}

.service-card > .wrap.detail > .detail > .content {
    margin-top: 16px;
    color: rgba(255, 255, 255, 0.8);
}

.service-card > .wrap.detail > .detail.normal {
    width: 240px;
}

.service-card > .wrap.detail > .detail.normal > .title {
    font-size: 24px;
    line-height: 32px;
}

.service-card > .wrap.detail > .detail.small {
    width: 256px;
    margin-top: 16px;
    flex-direction: column;
    align-items: center;
    display: none;
}

.service-card > .wrap.detail > .detail.small > .title {
    font-size: 16px;
    line-height: 24px;
}

.service-card > .wrap.detail > .detail.small > .content {
    margin-top: 16px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
}

.service-card > .wrap.detail > .detail.small > .icon {
    margin-top: 16px;
}

@media (min-width: 1440px) {
    .service-card > .wrap.detail > .title {
        transform: translateX(100px);
    }

    .service-card:hover > .wrap.detail > .title,
    .service-card.active > .wrap.detail > .title {
        transform: translateX(0);
    }
}

@media (max-width: 1439px) {
    .service-card > .wrap.detail {
        flex-direction: column;
    }

    .service-card > .wrap.detail > .title {
        width: 256px;
    }

    .service-card > .wrap.detail > .title > .icon,
    .service-card > .wrap.detail > .detail.normal {
        display: none;
    }

    .service-card:hover > .wrap.detail > .detail.small,
    .service-card.active > .wrap.detail > .detail.small {
        display: flex;
    }
}
