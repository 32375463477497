#about {
    display: flex;
    flex-direction: row-reverse;
}

@media (max-width: 1439px) {
    #about {
        flex-direction: column;
    }
}
