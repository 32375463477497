#contact-faq {

}

#contact-faq > header {
    user-select: none;
}

#contact-faq > article {
    margin-top: 16px;
}

@media (max-width: 833px) {
    #contact-faq > article {
        margin-top: 12px;
    }
}
