.home-card {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background: bottom left;
    background-size: cover;
    cursor: pointer;
}

.home-card-title-wrap {
    position: absolute;
    bottom: 48px;
    left: 20px;
    color: white;
    font-family: IBMPlexSansKR-Medium;
    line-height: 36px;
}

.home-card-title {
    font-size: 28px;
}

.home-card-desc {
    font-size: 18px;
}

@media (min-width: 834px) {
    .home-card-title-wrap {
        bottom: 48px;
        left: 50px;
        line-height: 52px;
    }
    
    .home-card-title {
        font-size: 48px;
    }
    
    .home-card-desc {
        font-size: 32px;
    }
}

@media (min-width: 1440px) {
    .home-card-title-wrap {
        bottom: 60px;
        left: 60px;
        line-height: 65px;
    }
    
    .home-card-title {
        font-size: 60px;
    }
    
    .home-card-desc {
        font-size: 40px;
    }
}
