#contact-process-item-list {
    width: 100%;
    position: relative;
}

#contact-process-item-list > .list {
    width: 100%;
    height: 176px;
    position: relative;
}

#contact-process-item-list > .list > .stepper {
    top: 79px;
    width: 11px;
    height: 19px;
    position: absolute;
    background: url(./stepper.png);
    background-size: cover;
}

#contact-process-item-list > .list > .contact-process-item {
    top: 0;
}

#contact-process-item-list > .list > .contact-process-item:nth-child(1) {
    left: 0;
}

#contact-process-item-list > .list > .stepper:nth-child(2) {
    left: 188px;
}

#contact-process-item-list > .list > .contact-process-item:nth-child(3) {
    left: 212px;
}

#contact-process-item-list > .list > .stepper:nth-child(4) {
    left: 398px;
}

#contact-process-item-list > .list > .contact-process-item:nth-child(5) {
    left: 420px;
}

#contact-process-item-list > .list > .stepper:nth-child(6) {
    left: 606px;
}

#contact-process-item-list > .list > .contact-process-item:nth-child(7) {
    left: 628px;
}

#contact-process-item-list > .list > .stepper:nth-child(8) {
    left: 814px;
}

#contact-process-item-list > .list > .contact-process-item:nth-child(9) {
    left: 836px;
}

#contact-process-item-list > .periods {
    margin-top: 22px;
    width: 100%;
    height: 32px;
    position: relative;
}

#contact-process-item-list > .periods > .contact-process-item-period:nth-child(1) {
    left: 212px;
}

#contact-process-item-list > .periods > .contact-process-item-period:nth-child(2) {
    left: 628px;
}

@media (max-width: 1439px) and (min-width: 834px) {
    #contact-process-item-list > .list {
        height: 118px;
    }

    #contact-process-item-list > .list > .stepper {
        width: 7px;
        height: 12px;
        top: 53px;
    }

    #contact-process-item-list > .list > .stepper:nth-child(2) {
        left: 126px;
    }

    #contact-process-item-list > .list > .contact-process-item:nth-child(3) {
        left: 142px;
    }

    #contact-process-item-list > .list > .stepper:nth-child(4) {
        left: 268px;
    }

    #contact-process-item-list > .list > .contact-process-item:nth-child(5) {
        left: 284px;
    }

    #contact-process-item-list > .list > .stepper:nth-child(6) {
        left: 410px;
    }

    #contact-process-item-list > .list > .contact-process-item:nth-child(7) {
        left: 426px;
    }

    #contact-process-item-list > .list > .stepper:nth-child(8) {
        left: 552px;
    }

    #contact-process-item-list > .list > .contact-process-item:nth-child(9) {
        left: 568px;
    }

    #contact-process-item-list > .periods {
        margin-top: 15px;
        width: 100%;
        height: 22px;
        position: relative;
    }

    #contact-process-item-list > .periods > .contact-process-item-period:nth-child(1) {
        left: 142px;
    }

    #contact-process-item-list > .periods > .contact-process-item-period:nth-child(2) {
        left: 426px;
    }
}

@media (max-width: 833px) {
    #contact-process-item-list > .list {
        height: 53px;
    }

    #contact-process-item-list > .list > .stepper {
        width: 3px;
        height: 6px;
        top: 24px;
    }

    #contact-process-item-list > .list > .stepper:nth-child(2) {
        left: 57px;
    }

    #contact-process-item-list > .list > .contact-process-item:nth-child(3) {
        left: 64px;
    }

    #contact-process-item-list > .list > .stepper:nth-child(4) {
        left: 125px;
    }

    #contact-process-item-list > .list > .contact-process-item:nth-child(5) {
        left: 132px;
    }

    #contact-process-item-list > .list > .stepper:nth-child(6) {
        left: 189px;
    }

    #contact-process-item-list > .list > .contact-process-item:nth-child(7) {
        left: 196px;
    }

    #contact-process-item-list > .list > .stepper:nth-child(8) {
        left: 253px;
    }

    #contact-process-item-list > .list > .contact-process-item:nth-child(9) {
        left: 260px;
    }

    #contact-process-item-list > .periods {
        margin-top: 15px;
        width: 100%;
        height: 22px;
        position: relative;
    }

    #contact-process-item-list > .periods > .contact-process-item-period:nth-child(1) {
        left: 64px;
    }

    #contact-process-item-list > .periods > .contact-process-item-period:nth-child(2) {
        left: 196px;
    }
}
