#clients-title {
    width: 100%;
    padding: 80px 208px 32px;
    box-sizing: border-box;
    flex-shrink: 0;
}

#clients-title > .description {
    margin-top: 32px;
}

@media (max-width: 1439px) and (min-width: 834px) {
    #clients-title {
        padding: 72px;
    }
}

@media (max-width: 833px) {
    #clients-title {
        padding: 32px 32px 24px;
    }

    #clients-title > .description {
        margin-top: 16px;
        font-size: 12px;
        line-height: 18px;
    }
}
