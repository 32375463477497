.contact-faq-item {
    margin-top: 16px;
    padding: 12px;
    width: 100%;
    min-height: 48px;
    border-radius: 4px;
    background: rgba(13, 12, 12, 0.1);
    box-sizing: border-box;
    position: relative;
}

.contact-faq-item:first-child {
    margin-top: 0;
}

.contact-faq-item > article {
    width: 100%;
    padding-right: 36px;
    box-sizing: border-box;
}

.contact-faq-item > article {
    margin-top: 12px;
    color: #0D0C0C;
    display: none;
}

.contact-faq-item > button {
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    position: absolute;
}

.contact-faq-item.opened {
    background: rgba(49, 31, 255, 0.1);
}

.contact-faq-item.opened > header {
    color: #311FFF;
}

.contact-faq-item.opened > article {
    display: block;
}

header:hover { 
    cursor: pointer;
}

@media (max-width: 833px) {
    .contact-faq-item {
        font-size: 12px;
        line-height: 18px;
        min-height: 42px;
    }

    .contact-faq-item > button,
    .contact-faq-item > button > svg {
        width: 18px;
        height: 18px;
    }
}

