#home-buttons {
    margin-top: 32px;
    height: 44px;
    display: flex;
    flex-wrap: wrap;
}

#home-buttons > a {
    padding: 0 16px;
    height: 44px;
    border: 1px solid #0D0C0C;
    background: #FFFFFF;
    border-radius: 4px;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    font-size: 14px;
    transition: .2s ease-out background;
}

#home-buttons > a:hover {
    background: #0D0C0C;
}

#home-buttons > a:hover > svg > path {
    fill: #FFFFFF;
}

#home-buttons > a:hover > div {
    color: #FFFFFF;
}

#home-buttons > a + a {
    margin-left: 12px;
}

#home-buttons > a > svg > path {
    transition: .2s ease-out fill;
}

#home-buttons > a > div {
    margin-left: 12px;
    transition: .2s ease-out color;
}

@media (max-width: 833px) {
    #home-buttons {
        margin-top: 16px;
    }
}
