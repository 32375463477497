.home-card-4 {
    background-image: url(./데브메이트_375_540캐러셀_장고버스.png);
}

@media (min-width: 834px) {
    .home-card-4 {
        background-image: url(./데브메이트-834_782캐러셀_장고버스-1.png);
    }
}

@media (min-width: 1440px) {
    .home-card-4 {
        background-image: url(./데브메이트-1440_487캐러셀_장고버스.png);
    }
}
