#services-swiper-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

#services-swiper-content .service-card {
    width: 100%;
    height: 100%;
}

#services-swiper-content .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 6px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    background: transparent;
    border-radius: 50%;
    opacity: 1;
}

#services-swiper-content .swiper-pagination-bullet-active {
    background: #FFFFFF;
}

.swiper {
    height: 100%;
}

.swiper-pagination {
    bottom: 24px !important;
}

@media (min-width: 834px) {
    #services-swiper-content {
        display: none;
    }
}
