#main-header-drawer-opener {
    width: 20px;
    height: 20px;
}

@media (min-width: 1440px) {
    #main-header-drawer-opener {
        display: none;
    }
}
