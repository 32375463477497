.main-header-menu-link {
    margin-left: 32px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0D0C0C;
    transition: color .2s;
}

.main-header-menu-link:first-child {
    margin-left: 0;
}

.main-header-menu-link:hover,
.main-header-menu-link.active {
    color: #311FFF;
}
