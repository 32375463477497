.portfolio-more-button {
    margin-top: 36px;
    padding: 0 16px;
    height: 44px;
    background: #311FFF;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.portfolio-more-button > .title {
    margin-left: 12px;
}

@media (max-width: 833px) {
    .portfolio-more-button {
        padding: 0 10px;
        height: 34px;
        font-size: 12px;
    }
}
