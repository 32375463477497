#main-drawer-wrap {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 3000;
    background: rgba(0, 0, 0, 0.8);
    transition: opacity .3s;
    opacity: 0;
}

#main-drawer-wrap.mounted {
    opacity: 1;
}

#main-drawer-wrap > .background {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}

#main-drawer-wrap > .drawer {
    top: 0;
    bottom: 0;
    width: 320px;
    padding: 32px;
    position: absolute;
    overflow-y: auto;
    box-sizing: border-box;
    z-index: 100;
    background: #FFFFFF;
    transition: right .3s;
    right: -320px;
}

#main-drawer-wrap.mounted > .drawer {
    right: 0;
}

#main-drawer-wrap > .drawer::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

#main-drawer-wrap > .drawer::-webkit-scrollbar-track {
    padding-right: 8px;
    background: transparent;
}

#main-drawer-wrap > .drawer::-webkit-scrollbar-thumb {
    background: rgba(13, 12, 12, 0.1);
    border-radius: 10px;
}

#main-drawer-wrap > .drawer > .close {

}

#main-drawer-wrap .drawer > .link,
#main-drawer-wrap .drawer > .sub-link {
    font-weight: 600;
    line-height: 20px;
    transition: .2s color;
    display: block;
    user-select: none;
}

#main-drawer-wrap .drawer > .link:hover,
#main-drawer-wrap .drawer > .link.active,
#main-drawer-wrap .drawer > .sub-link:hover,
#main-drawer-wrap .drawer > .sub-link.active {
    color: #311FFF;
}

#main-drawer-wrap .drawer > .link {
    margin-top: 32px;
    font-size: 14px;
}

#main-drawer-wrap .drawer > .link:first-child {
    margin-top: 0;
}

#main-drawer-wrap .drawer > .sub-link {
    margin-top: 8px;
    font-size: 12px;
    color: rgba(13, 12, 12, 0.6);
}

@media (min-width: 834px) {
    #main-drawer-wrap > .drawer > .close {
        display: none;
    }

    #main-drawer-wrap > .drawer > .close + .link {
        margin-top: 0;
    }
}

@media (max-width: 833px) {
    #main-drawer-wrap > .drawer {
        width: 100%;
        right: -100%;
    }

    #main-drawer-wrap.mounted > .drawer {
        right: 0;
    }
}
