.contact-process-item-period {
    height: 32px;
    box-sizing: border-box;
    border: solid 2px #311FFF;
    border-top: none;
    border-bottom: none;
    position: absolute;
}

.contact-process-item-period[data-period-count="1"] {
    width: calc((176px + 32px) * 1 - 32px);
}

.contact-process-item-period[data-period-count="2"] {
    width: calc((176px + 32px) * 2 - 32px);
}

.contact-process-item-period:before {
    left: 0;
    right: 0;
    top: 15px;
    content: '';
    height: 2px;
    display: block;
    background: #311FFF;
    position: absolute;
}

.contact-process-item-period > .period {
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    height: 32px;
    font-size: 18px;
    font-weight: bold;
    line-height: 32px;
    padding: 0 12px;
    color: #311FFF;
    background: #FFFFFF;
    box-sizing: border-box;
    position: absolute;
}

@media (max-width: 1439px) {
    .contact-process-item-period {
        border-width: 1px;
    }

    .contact-process-item-period:before {
        height: 1px;
    }
}

@media (max-width: 1439px) and (min-width: 834px) {
    .contact-process-item-period {
        height: 22px;
    }

    .contact-process-item-period[data-period-count="1"] {
        width: calc((118px + 24px) * 1 - 24px);
    }

    .contact-process-item-period[data-period-count="2"] {
        width: calc((118px + 24px) * 2 - 24px);
    }

    .contact-process-item-period:before {
        top: 10px;
    }

    .contact-process-item-period > .period {
        height: 22px;
        line-height: 22px;
        padding: 0 8px;
        font-size: 12px;
    }
}


@media (max-width: 833px) {
    .contact-process-item-period {
        height: 10px;
    }

    .contact-process-item-period[data-period-count="1"] {
        width: calc((53px + 11px) * 1 - 11px);
    }

    .contact-process-item-period[data-period-count="2"] {
        width: calc((53px + 11px) * 2 - 11px);
    }

    .contact-process-item-period:before {
        top: 4px;
    }

    .contact-process-item-period > .period {
        height: 10px;
        line-height: 10px;
        padding: 0 3px;
        font-size: 6px;
    }
}
