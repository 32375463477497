#main-header-menu {
    height: 20px;
    display: flex;
    align-items: center;
}

@media (max-width: 1439px) {
    #main-header-menu {
        display: none;
    }
}
