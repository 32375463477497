.select-box {
    height: 42px;
    padding: 0 12px;
    background: #FFFFFF;
    border: 1px solid rgba(13, 12, 12, 0.1);
    box-sizing: border-box;
    border-radius: 2px;
    position: relative;
    cursor: pointer;
    user-select: none;
}

.select-box.opened {
    border-color: #311FFF;
}

.select-box > .placeholder,
.select-box > .value {
    font-size: 14px;
    line-height: 40px;
}

.select-box > .placeholder {
    color: rgba(13, 12, 12, 0.5);
}

.select-box > .value {
    color: #0D0C0C;
}

.select-box > button {
    top: 8px;
    right: 11px;
    position: absolute;
}

.select-box > .select-box-items {
    padding: 8px 8px 0;
    position: fixed;
    display: none;
    background: #FFFFFF;
    border: 1px solid rgba(13, 12, 12, 0.1);
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    z-index: 1500;
}

.select-box > .select-box-items > .wrap {
    width: 100%;
    max-height: 164px;
    overflow: auto;
}

.select-box > .select-box-items > .wrap::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.select-box > .select-box-items > .wrap::-webkit-scrollbar-track {
    padding-right: 8px;
    background: transparent;
}

.select-box > .select-box-items > .wrap::-webkit-scrollbar-thumb {
    background: rgba(13, 12, 12, 0.1);
    border-radius: 10px;
}

.select-box.opened > .select-box-items {
    display: block;
}

.select-box.opened > .select-box-items > .wrap > .select-box-item {
    height: 36px;
    line-height: 36px;
    padding: 0 12px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
