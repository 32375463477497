.page-slide {
    width: 100%;
    min-height: 100%;
    padding-top: 84px;
    box-sizing: border-box;
    position: relative;
}

@media (max-width: 1439px) {
    .page-slide {
        min-height: auto;
    }
}
