.clients-company-row-wrap {
    height: 90px;
    display: flex;
    transition: margin-left .3s;
    position: relative;
}

.clients-company-row-wrap + .clients-company-row-wrap {
    margin-top: 48px;
}

@media (max-width: 833px) {
    .clients-company-row-wrap {
        height: 45px;
    }

    .clients-company-row-wrap + .clients-company-row-wrap {
        margin-top: 24px;
    }
}
