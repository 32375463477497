.service-card {
    box-sizing: border-box;
    position: relative;
}

.service-card:nth-child(2),
.service-card:nth-child(4) {
    border-left: 1px solid rgba(13, 12, 12, 0.1);
}

.service-card:nth-child(3),
.service-card:nth-child(4) {
    border-top: 1px solid rgba(13, 12, 12, 0.1);
}

.service-card > .wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: absolute;
    display: flex;
}

.service-card > .wrap > .title {
    text-align: center;
}

.service-card > .wrap > .title > .no {
    font-weight: 900;
    font-size: 32px;
    line-height: 48px;
}

.service-card > .wrap > .title > .title {
    margin-top: 16px;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
}

.service-card > .wrap > .title > .icon {
    margin-top: 16px;
}

@media (min-width: 1440px) {

}

@media (max-width: 1439px) and (min-width: 834px) {

}

@media (max-width: 833px) {

}
