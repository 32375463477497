#portfolio-detail {
    user-select: none;
}

#portfolio-detail > .content {
    padding: 36px 208px;
}

#portfolio-detail > .content > .back {
    padding: 0 12px;
    height: 36px;
    border: 1px solid #0D0C0C;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

#portfolio-detail > .content > .back > .title {
    margin-left: 8px;
    font-size: 14px;
}

#portfolio-detail > .content > .title {
    margin-top: 36px;
}

#portfolio-detail > .content > .tab-box {
    margin-top: 36px;
    width: 100%;
    height: 44px;
    display: flex;
}

#portfolio-detail > .content > .tab-box > .tab {
    height: 44px;
    line-height: 40px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    border-bottom: solid 4px rgba(13, 12, 12, 0.1);
    color: rgba(13, 12, 12, 0.5);
    flex-grow: 1;
}

#portfolio-detail > .content > .tab-box > .tab.current {
    color: #0D0C0C;
    border-color: #CCA63D;
}

#portfolio-detail > .content > .items:after {
    content: '';
    display: block;
    clear: both;
}

@media (min-width: 1440px) {
    #portfolio-detail {
        min-height: calc(100% - 224px) !important;;
    }
}

@media (max-width: 1439px) {
}

@media (min-width: 834px) {
    #portfolio-detail {
        min-height: calc(100% - 224px) !important;;
    }
}

@media (max-width: 1439px) and (min-width: 834px) {
    #portfolio-detail > .content {
        padding: 36px 0;
    }

    #portfolio-detail > .content > .back {
        margin-left: 72px;
    }

    #portfolio-detail > .content > .title {
        padding: 0 72px;
    }
}

@media (max-width: 833px) {
    #portfolio-detail {
        min-height: calc(100% - 184px) !important;;
    }

    #portfolio-detail > .content {
        padding: 32px 0;
    }

    #portfolio-detail > .content > .back {
        margin-left: 32px;
    }

    #portfolio-detail > .content > .title {
        padding: 0 32px;
    }

    #portfolio-detail > .content > .tab-box {
        margin-top: 32px;
    }

    #portfolio-detail > .content > .items {

    }
}

