.page-slide-title {
    font-weight: 900;
    font-size: 72px;
    line-height: 78px;
    color: #0D0C0C;
}

.page-slide-title > span {
    color: #311FFF;
}

.page-slide-title.white {
    color: #FFFFFF;
}

.page-slide-title.white > span {
    color: #CCA63D;
}

@media (max-width: 833px) {
    .page-slide-title {
        font-size: 32px;
        line-height: 36px;
    }
}
