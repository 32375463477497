.popup-title {
    color: #311FFF;
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    user-select: none;
}

@media (max-width: 833px) {
    .popup-title  {
        font-size: 32px;
        line-height: 36px;
    }
}
