#vllage-terms {
    display: block;
    position: relative;
    z-index: 9999;
    background-color: white;
}

.terms-pre {
    max-width: 100vw;
    word-wrap: wrap;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
}