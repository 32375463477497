#main-footer {
    height: 250px;
    padding: 32px 208px;
    background: rgba(13, 12, 12, 0.1);
    color: rgba(13, 12, 12, 0.5);
    font-size: 12px;
    line-height: 18px;
    box-sizing: border-box;
}

#main-footer > .company {
    margin-top: 16px;
    font-size: 14px;
    line-height: 20px;
}

#main-footer > .row {
    margin-top: 8px;
}

#main-footer > .row > span + span {
    margin-left: 12px;
}

#main-footer > .row > span > .underline {
    text-decoration: underline;
}

#main-footer > .copyright {
    font-weight: bold;
}

@media (max-width: 1439px) and (min-width: 834px) {
    #main-footer {
        padding: 32px 72px;
    }
}

@media (max-width: 833px) {
    #main-footer {
        height: 184px;
        padding: 32px;
        font-size: 8px;
        line-height: 10px;
    }

    #main-footer > .company {
        font-size: 14px;
        line-height: 20px;
    }

    #main-footer > .copyright {
        font-size: 10px;
        line-height: 12px;
    }
}
