#sidebar {
    top: calc(50% - 123px);
    right: 36px;
    width: 60px;
    position: fixed;
    z-index: 2000;
}

#sidebar > .message,
#sidebar > .sns {
    width: 60px;
    box-sizing: border-box;
    border: solid 1px;
    border-radius: 8px;
}

#sidebar > .message {
    height: 60px;
    background: #311FFF;
    border-color: #FFFFFF;
}

#sidebar > .message > svg {
    vertical-align: middle;
}

#sidebar > .sns {
    margin-top: 12px;
    background: #FFFFFF;
    border-color: rgba(13, 12, 12, 0.1);
}

#sidebar > .sns > a {
    width: 58px;
    padding: 18px;
    display: block;
    box-sizing: border-box;
}

#sidebar > .sns > a > svg {
    display: block;
}

#sidebar > .sns > .divider {
    margin: 0 auto;
    width: 24px;
    height: 1px;
    background: rgba(13, 12, 12, 0.1);
}

#sidebar > button.message {
    font-size: 0px;
}

@media (max-width: 833px) {
    #sidebar {
        right: 8px;
        width: 32px;
    }

    #sidebar > .message,
    #sidebar > .sns {
        width: 32px;
        border-radius: 4px;
    }

    #sidebar > .message {
        height: 32px;
    }

    #sidebar > .message > svg,
    #sidebar > .sns > a > svg {
        width: 16px;
    }

    #sidebar > .sns > a {
        width: 30px;
        padding: 8px;
    }

    #sidebar > .sns > .divider {
        width: 16px;
    }
}