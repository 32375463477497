#clients-company-buttons {
    display: flex;
    padding: 64px 208px 0;
}

#clients-company-buttons .link {
    margin-left: 12px;
    padding: 0 16px;
    height: 44px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: solid 1px #FFFFFF;
    border-radius: 4px;
    background: transparent;
    color: #FFFFFF;
}

#clients-company-buttons .link > svg + div {
    margin-left: 12px;
}

#clients-company-buttons .contact {
    color: #0D0C0C;
    background: #FFFFFF;
    flex-shrink: 0;
    flex-grow: 0;
}

#clients-company-buttons > .portfolio {
    display: flex;
    flex-shrink: 0;
}

#clients-company-buttons > .portfolio > .link:first-child {
    margin-left: 0;
}

@media (max-width: 1439px) {
    #clients-company-buttons {
        flex-direction: column;
    }

    #clients-company-buttons > .portfolio {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
    }
}

@media (max-width: 1439px) and (min-width: 834px) {
    #clients-company-buttons {
        padding: 64px 72px 0;
    }

    #clients-company-buttons > .portfolio > .link {
        flex-grow: 1;
        flex-shrink: 1;
    }

    #clients-company-buttons > .contact {
        margin-top: 12px;
        margin-left: 0;
    }
}

@media (max-width: 833px) {
    #clients-company-buttons {
        padding: 64px 32px 0;
    }

    #clients-company-buttons .link {
        margin-top: 16px;
        margin-left: 0;
    }

    #clients-company-buttons > .portfolio {
        flex-direction: column;
    }

    #clients-company-buttons > .portfolio > .link:first-child {
        margin-top: 0;
    }
}

