#contact {

}

#contact > .content {
    padding: 84px 208px;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid rgba(13, 12, 12, 0.1);
}

#contact > .content:first-child {
    border-top: none;
}

#contact > .content:last-child {
    padding-bottom: 116px;
}

#contact > .content > header {
    color: #311FFF;
    font-weight: 900;
    font-size: 32px;
    line-height: 48px;
}

@media (max-width: 1439px) and (min-width: 834px) {
    #contact > .content {
        padding: 56px 72px;
    }
}

@media (min-width: 834px) {
    #contact > .content {
        min-height: calc(100% - 224px) !important;;
    }
}

@media (max-width: 833px) {
    #contact > .content {
        padding: 32px;
    }
}
