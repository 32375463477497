#virtual-office {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background:
            linear-gradient(0deg, #341FFF, #341FFF),
            url(./background_coming_soon.png),
            center;
    background-size: cover;
    background-blend-mode: multiply, normal;
}

#virtual-office > .coming-soon {
    font-weight: 600;
    font-size: 72px;
    line-height: 72px;
    color: #FFFFFF;
    user-select: none;
}

@media (max-width: 833px) {
    #virtual-office > .coming-soon {
        font-size: 32px;
        line-height: 32px;
    }
}

