.contact-process-item {
    width: 176px;
    height: 176px;
    background: #0D0C0C;
    border-radius: 4px;
    position: absolute;
}

.contact-process-item > .line {
    top: 8px;
    left: 8px;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    border: solid 1px #CCA63D;
    border-radius: 2px;
    box-sizing: border-box;
    position: absolute;
}

.contact-process-item > .content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #CCA63D;
    font-size: 24px;
    line-height: 34px;
    font-weight: bold;
    text-align: center;
}

@media (max-width: 1439px) and (min-width: 834px) {
    .contact-process-item {
        width: 118px;
        height: 118px;
    }

    .contact-process-item > .line {
        top: 5px;
        left: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
    }

    .contact-process-item > .content {
        font-size: 16px;
        line-height: 24px;
    }
}

@media (max-width: 833px) {
    .contact-process-item {
        width: 53px;
        height: 53px;
    }

    .contact-process-item > .line {
        top: 3px;
        left: 3px;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
    }

    .contact-process-item > .content {
        font-size: 8px;
        line-height: 14px;
    }
}

