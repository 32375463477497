.portfolio-slide > .content {
    padding: 56px 208px 136px;
    box-sizing: border-box;
}

@media (max-width: 1439px) and (min-width: 834px) {
    .portfolio-slide > .content {
        padding: 56px 72px 430px;
    }
}

@media (min-width: 834px) {
    .portfolio-slide {
        min-height: calc(100% - 224px) !important;;
    }
}

@media (max-width: 833px) {
    .portfolio-slide > .content {
        min-height: calc(100% - 184px) !important;
        padding: 32px 32px 196px;
    }
}
