.clients-company-row {
    height: 90px;
    padding-right: 64px;
    position: absolute;
}

.clients-company-row > .content {
    display: flex;
}

.clients-company-row > .content > img {
    height: 90px;
}

.clients-company-row > .content > img + img {
    margin-left: 64px;
}

@media (max-width: 833px) {
    .clients-company-row {
        height: 45px;
        padding-right: 32px;
    }

    .clients-company-row > .content > img {
        height: 45px;
    }

    .clients-company-row > .content > img + img {
        margin-left: 32px;
    }
}
