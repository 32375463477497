#services {
    height: 100%;
    padding: 84px 208px 0;
}

@media (max-width: 1439px) {
    #services {
        padding: 84px 0 0;
    }
}
