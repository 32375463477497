#clients-company {
    padding: 64px 0 68px;
    flex-grow: 1;
    flex-shrink: 1;
    background: #0D0C0C;
    user-select: none;
    box-sizing: border-box;
}

@media (max-width: 1439px) {
    #clients-company {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

@media (max-width: 1439px) and (min-width: 834px) {
    #clients-company {
        padding: 64px 0 274px;
        height: 730px;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

@media (max-width: 833px) {
    #clients-company {
        padding: 64px 0 124px;
        height: 530px;
    }
}
