.textarea-box {
    box-sizing: border-box;
    position: relative;
}

.textarea-box > .title {
    top: 4px;
    left: 12px;
    font-size: 10px;
    line-height: 14px;
    position: absolute;
    display: none;
    color: #0D0C0C;
}

.textarea-box > textarea {
    resize: none;
    padding: 8px 12px;
    width: 100%;
    height: 100%;
    display: block;
    line-height: 20px;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 2px;
    background: #FFFFFF;
    border: 1px solid rgba(13, 12, 12, 0.1);
}

.textarea-box > textarea::placeholder {
    color: rgba(13, 12, 12, 0.5);
}

.textarea-box.hasValue > textarea,
.textarea-box > textarea:focus {
    padding-top: 18px;
}

.textarea-box > textarea:focus {
    border-color: #311FFF;
}

.textarea-box > textarea:focus + .title {
    color: #311FFF;
}

.textarea-box > textarea:focus::placeholder {
    color: #7C7C7C;
}

.textarea-box.hasValue > .title,
.textarea-box > textarea:focus + .title {
    display: block;
}
