.portfolio-popup-content-item {
    margin-top: 16px;
    font-size: 14px;
    line-height: 20px;
}

.portfolio-popup-content-item:first-child {
    margin-top: 0;
}

.portfolio-popup-content-item > header {
    font-weight: bold;
    color: #311FFF;
}

.portfolio-popup-content-item > article {
    margin-top: 4px;
    color: #0D0C0C;
}

@media (min-width: 1440px) {

}

@media (max-width: 1439px) and (min-width: 834px) {

}

@media (max-width: 833px) {
    .portfolio-popup-content-item > header,
    .portfolio-popup-content-item > article {
        font-size: 12px;
        line-height: 18px;
    }
}
