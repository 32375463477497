@keyframes anim-photo-cover-width {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

@keyframes anim-photo-cover-height {
    from {
        height: 100%;
    }
    to {
        height: 0;
    }
}

.portfolio-photo {
    width: 240px;
    height: 240px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.portfolio-photo > .background,
.portfolio-photo > .blur {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.portfolio-photo > .background {
    background-size: cover;
    background-position: center;
}

.portfolio-photo > .blur {
    transition: opacity .3s;
    opacity: 1;
    background-image: linear-gradient(0deg, rgba(49, 31, 255, 0.6), rgba(49, 31, 255, 0.6));
}

.portfolio-photo > .cover {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #CCA63D;
    z-index: 100;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-delay: .5s;
}

.portfolio-photo:nth-child(1) > .cover {
    top: 0;
    right: 0;
    animation-name: anim-photo-cover-width;
}

.portfolio-photo:nth-child(2) > .cover {
    top: 0;
    left: 0;
    animation-name: anim-photo-cover-height;
}

.portfolio-photo:nth-child(3) > .cover {
    left: 0;
    bottom: 0;
    animation-name: anim-photo-cover-height;
}

.portfolio-photo > .info {
    left: 0;
    right: 0;
    bottom: -64px;
    height: 64px;
    padding: 8px 0 0 8px;
    background: #CCA63D;
    box-sizing: border-box;
    position: absolute;
    transition: bottom .2s;
    transition-delay: .1s;
}

.portfolio-photo > .info > header {
    font-weight: bold;
}

.portfolio-photo > .info > article {
    font-size: 14px;
}

.portfolio-photo:hover > .blur {
    opacity: 0;
}

.portfolio-photo:hover > .info {
    bottom: 0;
}

@media (min-width: 1440px) {
    .portfolio-photo {
        position: absolute;
    }
}

@media (max-width: 1439px) {
    .portfolio-photo {
        position: relative;
    }
}

@media (max-width: 1439px) and (min-width: 834px) {

}

@media (max-width: 833px) {
    .portfolio-photo {
        height: 125px;
    }

    .portfolio-photo > .info {
        height: 54px;
        bottom: -54px;
    }

    .portfolio-photo > .info > header {
        font-size: 14px;
        line-height: 20px;
    }

    .portfolio-photo > .info > article {
        font-size: 12px;
        line-height: 18px;
    }
}
