#main-header {
    top: 0;
    left: 0;
    right: 0;
    height: 84px;
    position: fixed;
    z-index: 1000;
    border: 1px solid rgba(13, 12, 12, 0.1);
    background: #FFFFFF;
    box-sizing: border-box;
    padding: 0 208px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
}

@media (max-width: 1399px) and (min-width: 834px) {
    #main-header {
        padding: 0 72px;
    }
}

@media (max-width: 833px) {
    #main-header {
        padding: 0 32px;
    }
}
