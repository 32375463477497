html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: rgba(13, 12, 12, 0.8);
  background: #FFFFFF;
  font-family: IBM Plex Sans KR, sans-serif;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

p {
  margin: 0;
}

button, input, textarea {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  box-shadow: none;
  font-family: IBM Plex Sans KR, sans-serif;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  user-select: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6, a, button, header {
  color: #0D0C0C;
}

.eng {
  font-family: Metropolis, sans-serif;
}

address {
  font-style: normal;
}
