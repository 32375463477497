@keyframes anim-mask {
    0% {
        left: 0;
        width: 0;
    }
    42% {
        left: 0;
        right: 0;
        width: 100%;
    }
    58% {
        right: 0;
        width: 100%;
    }
    100% {
        left: auto;
        right: 0;
        width: 0;
    }
}

@keyframes anim-text-before {
    0%, 45% {
        opacity: 1;
    }
    50%, 100% {
        opacity: 0;
    }
}

@keyframes anim-text-after {
    0%, 45% {
        opacity: 0;
    }
    50%, 100% {
        opacity: 1;
    }
}

#home-content {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    z-index: 100;
    user-select: none;
    position: relative;
    display: flex;
    flex-direction: column;
}

#home-content > .title-wrap {
    flex-shrink: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 328px;
    padding: 48px 0 0 208px;
    box-sizing: border-box;
}

#home-content > .title-wrap > .title {

}

#home-content > .title-wrap > .title > .word {
    width: 240px;
    height: 78px;
    display: inline-block;
    position: relative;
}

#home-content > .title-wrap > .title > .word > .text {
    left: 0;
    animation-duration: 1.25s;
    animation-delay: .5s;
    animation-fill-mode: forwards;
    position: absolute;
}

#home-content > .title-wrap > .title > .word > .text.after {
    opacity: 0;
}

#home-content > .title-wrap > .title > .word > .mask {
    top: -6px;
    left: -10px;
    width: 240px;
    height: 90px;
    position: absolute;
}

#home-content > .title-wrap > .title > .word > .mask > .bar {
    top: 0;
    height: 100%;
    position: absolute;
}

#home-content > .title-wrap > .title > .word > .mask-1 {
    z-index: 100;
}

#home-content > .title-wrap > .title > .word > .mask-1 > .bar {
    background: #CCA63D;
}

#home-content > .title-wrap > .title > .word > .mask-2 {
    z-index: 200;
}

#home-content > .title-wrap > .title > .word > .mask-2 > .bar {
    background: #311FFF;
}

#home-content > .title-wrap > .title > .word.animating > .text.before {
    animation-name: anim-text-before;
}

#home-content > .title-wrap > .title > .word.animating > .text.after {
    animation-name: anim-text-after;
}

#home-content > .title-wrap > .title > .word.animating > .mask > .bar {
    animation-name: anim-mask;
}

#home-content > .title-wrap > .title > .word.animating > .mask-1 > .bar {
    animation-duration: 1.25s;
    animation-delay: .5s;
}

#home-content > .title-wrap > .title > .word.animating > .mask-2 > .bar {
    animation-duration: .75s;
    animation-delay: .75s;
}

@media (max-width: 1399px) and (min-width: 834px) {
    #home-content > .title-wrap {
        padding: 48px 48px 0;
    }
}

@media (max-width: 833px) {
    #home-content > .title-wrap {
        padding: 32px 32px 0;
        height: 187px;
    }

    #home-content > .title-wrap > .title > .word {
        height: 36px;
    }

    #home-content > .title-wrap > .title > .word {
        width: 110px;
    }

    #home-content > .title-wrap > .title > .word > .mask {
        top: -4px;
        left: -5px;
        width: 110px;
        height: 44px;
        position: absolute;
    }
}
