#about-content {
    width: 624px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

#about-content > .headlines {
    padding: 56px 0 56px 208px;
    width: 624px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(13, 12, 12, 0.1);
}

#about-content > .headlines > h2 {
    font-weight: bold;
    font-size: 64px;
    line-height: 78px;
}

#about-content > .headlines > p {
    margin-top: 24px;
    font-size: 16px;
    line-height: 24px;
}

#about-content > .headlines > p > span {
    color: #311FFF;
    font-weight: bold;
}

#about-content > .quotes {
    padding: 56px 0 0 208px;
    box-sizing: border-box;
}

#about-content > .quotes > h3 {
    font-weight: 800;
    font-size: 24px;
    line-height: 28px;
}

#about-content > .quotes > h3 ~ h3 {
    margin-top: 36px;
}

#about-content > .quotes > p {
    margin-top: 12px;
    font-size: 14px;
    line-height: 24px;
}

@media (max-width: 1439px) {
    #about-content,
    #about-content > .headlines,
    #about-content > .quotes {
        width: 100%;
    }
}

@media (max-width: 1439px) and (min-width: 834px) {
    #about-content > .headlines {
        padding: 36px 72px 36px;
    }

    #about-content > .quotes {
        padding: 36px 72px 82px 72px;
    }
}

@media (max-width: 833px) {
    #about-content > .headlines {
        padding: 32px;
    }

    #about-content > .headlines > h2 {
        font-size: 32px;
        line-height: 36px;
    }

    #about-content > .headlines > p {
        margin-top: 12px;
        font-size: 12px;
        line-height: 18px;
    }

    #about-content > .quotes {
        padding: 32px 32px 106px;
    }

    #about-content > .quotes > h3 {
        font-size: 18px;
        line-height: 20px;
    }

    #about-content > .quotes > h3 ~ h3 {
        margin-top: 32px;
    }

    #about-content > .quotes > p {
        font-size: 12px;
        line-height: 18px;
    }
}

