#about-title {
    padding: 56px;
    box-sizing: border-box;
    flex-grow: 1;
    flex-shrink: 1;
    background:
            linear-gradient(0deg, rgba(49, 31, 255, 0.8),
            rgba(49, 31, 255, 0.8)),
            linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)),
            url(./background_about.jpg) center;
    background-blend-mode: multiply, normal, normal;
    background-size: cover;
    user-select: none;
    position: relative;
}

#about-title > .cover {
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: #CCA63D;
    position: absolute;
    z-index: 100;
    transition: width 0.7s ease;
    transition-delay: 0.25s;
}

#about-title.animating > .cover {
    width: 0;
}

@media (max-width: 1439px) {
    #about-title {
        width: 100%;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

@media (max-width: 1439px) and (min-width: 834px) {
    #about-title {
        padding: 56px 72px;
        height: 400px;
    }
}

@media (max-width: 833px) {
    #about-title {
        padding: 32px;
        height: 180px;
    }
}
