#services-content {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(13, 12, 12, 0.1);
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

#services-content > .service-card {
    width: 50%;
    height: 50%;
    flex-shrink: 0;
}

@media (max-width: 833px) {
    #services-content {
        display: none;
    }
}
