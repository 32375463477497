.main-header-menu-multi-link {
    cursor: pointer;
    position: relative;
}

.main-header-menu-multi-link:not(.active):hover {
    color: #0D0C0C;
}

.main-header-menu-multi-link > .back {
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    position: absolute;
}

.main-header-menu-multi-link:hover > .title {
    text-decoration: underline;
}

.main-header-menu-multi-link > .dropdown {
    top: 30px;
    left: 0;
    padding: 4px 0;
    width: 156px;
    background: #FFFFFF;
    border: 1px solid rgba(13, 12, 12, 0.1);
    box-sizing: border-box;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
    border-radius: 4px;
    position: absolute;
    display: none;
    z-index: 100;
}

.main-header-menu-multi-link > .dropdown > a {
    padding: 0 12px;
    width: 100%;
    height: 36px;
    font-size: 14px;
    line-height: 36px;
    box-sizing: border-box;
    display: block;
    font-weight: normal;
    transition: background .2s ease-out;
}

.main-header-menu-multi-link > .dropdown > a:hover {
    background: rgba(13, 12, 12, 0.1);
}

.main-header-menu-multi-link:hover > .dropdown,
.main-header-menu-multi-link > .back:hover ~ .dropdown {
    display: block;
}
