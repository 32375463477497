.portfolio-popup-content {
    margin-top: 32px;
}

.portfolio-popup-content > .photos {

}

.portfolio-popup-content > .photos > .photo {
    width: 100%;
    margin-top: 32px;
    display: block;
}

.portfolio-popup-content > .photos > .photo:first-child {
    margin-top: 0;
}

.portfolio-popup-content > .content {
    margin-top: 16px;
    flex-shrink: 0;
    flex-grow: 0;
}

.swiper-portfolio {
    max-height: 400px;
}

.photos {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
}

@media (min-width: 1440px) {
    .portfolio-popup-content > .content {
        margin-left: 28px;
    }
}

@media (max-width: 1439px) {
    .portfolio-popup-content {
        flex-direction: column-reverse;
    }
}

@media (max-width: 1439px) and (min-width: 834px) {
    .portfolio-popup-content > .content {
        width: 100%;
    }

    .portfolio-popup-content > .photos {
        margin-top: 32px;
    }
}

@media (max-width: 833px) {
    .portfolio-popup-content {
        margin-top: 18px;
    }

    .portfolio-popup-content > .photos {
        margin-top: 18px;
    }

    .portfolio-popup-content > .photos > .photo {
        margin-top: 18px;
    }

    .portfolio-popup-content > .photos > .photo:first-child {
        margin-top: 0;
    }

    .swiper-portfolio {
        max-height: 300px;
    }
    
    .photos {
        width: 100%;
        max-height: 300px;
        object-fit: contain;
    }    
}
