.input-box {
    height: 42px;
    box-sizing: border-box;
    position: relative;
}

.input-box > .title {
    top: 4px;
    left: 12px;
    color: #311FFF;
    font-size: 10px;
    line-height: 14px;
    position: absolute;
    display: none;
}

.input-box > input {
    padding: 0 12px;
    width: 100%;
    height: 42px;
    display: block;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid rgba(13, 12, 12, 0.1);
    border-radius: 2px;
    background: #FFFFFF;
}

.input-box > input::placeholder {
    color: rgba(13, 12, 12, 0.5);
}

.input-box.hasValue > input,
.input-box > input:focus {
    padding-top: 14px;
    line-height: 20px;
}

.input-box > input:focus {
    border-color: #311FFF;
}

.input-box > input:focus::placeholder {
    color: rgba(13, 12, 12, 0.5);
}

.input-box.hasValue .title,
.input-box > input:focus + .title {
    display: block;
}

.input-box.hasValue:not(:focus) .title {
    color: #0D0C0C;
}
