@keyframes anim-detail-photo-cover-width {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

@keyframes anim-detail-photo-cover-height {
    from {
        height: 100%;
    }
    to {
        height: 0;
    }
}

.portfolio-detail-item {
    width: calc((100% - 4px) / 3);
    height: 256px;
    float: left;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.portfolio-detail-item:nth-child(n + 4) {
    margin-top: 4px;
}

.portfolio-detail-item:nth-child(3n + 2),
.portfolio-detail-item:nth-child(3n + 3) {
    margin-left: 2px;
}

.portfolio-detail-item > .background,
.portfolio-detail-item > .blur {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.portfolio-detail-item > .background {
    background-size: cover;
    background-position: center;
}

.portfolio-detail-item > .blur {
    transition: opacity .3s;
    opacity: 1;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

.portfolio-detail-item > .cover {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #CCA63D;
    z-index: 100;
    animation-fill-mode: forwards;
    animation-duration: 1s;
    animation-delay: .5s;
}

.portfolio-detail-item:nth-child(4n + 1) > .cover {
    top: 0;
    right: 0;
    animation-name: anim-photo-cover-width;
}

.portfolio-detail-item:nth-child(4n + 2) > .cover {
    left: 0;
    bottom: 0;
    animation-name: anim-photo-cover-height;
}

.portfolio-detail-item:nth-child(4n + 3) > .cover {
    top: 0;
    left: 0;
    animation-name: anim-photo-cover-height;
}

.portfolio-detail-item:nth-child(4n + 4) > .cover {
    left: 0;
    bottom: 0;
    animation-name: anim-photo-cover-width;
}

.portfolio-detail-item > .info {
    left: 0;
    right: 0;
    bottom: 0;
    height: 72px;
    padding: 12px;
    box-sizing: border-box;
    position: absolute;
}

.portfolio-detail-item > .info > header,
.portfolio-detail-item > .info > article {
    z-index: 100;
    position: relative;
    color: #FFFFFF;
    transition: color .3s;
}

.portfolio-detail-item > .info > header {
    font-weight: bold;
}

.portfolio-detail-item > .info > article {
    font-size: 14px;
}

.portfolio-detail-item > .info > .info-background {
    left: 0;
    bottom: -72px;
    width: 100%;
    height: 72px;
    background: #CCA63D;
    position: absolute;
    z-index: 0;
    transition: bottom .3s;
}

.portfolio-detail-item:hover > .blur {
    opacity: 0;
}

.portfolio-detail-item:hover > .info > header,
.portfolio-detail-item:hover > .info > article {
    color: #0D0C0C;
}

.portfolio-detail-item:hover > .info > .info-background {
    bottom: 0;
}

@media (max-width: 833px) {
    .portfolio-detail-item {
        width: 100%;
        margin-top: 2px;
    }

    .portfolio-detail-item:first-child {
        margin-top: 0;
    }

    .portfolio-detail-item:nth-child(n + 4) {
        margin-top: 2px;
    }

    .portfolio-detail-item:nth-child(3n + 2),
    .portfolio-detail-item:nth-child(3n + 3) {
        margin-left: 0;
    }
}
