.portfolio-photo-box {
    right: 0;
    bottom: 0;
    z-index: 0;
    user-select: none;
    position: absolute;
}

@media (min-width: 1440px) {
    .portfolio-photo-box > * {
        position: absolute;
    }

    .portfolio-photo-box > *:nth-child(1) {
        right: 480px;
        bottom: 0;
    }

    .portfolio-photo-box > *:nth-child(2) {
        right: 240px;
        bottom: 240px;
    }

    .portfolio-photo-box > *:nth-child(3) {
        right: 0;
        bottom: 0;
    }

    .portfolio-photo-box.rightward > *:nth-child(3) {
        bottom: 480px;
    }
}

@media (max-width: 1439px) {
    .portfolio-photo-box {
        left: 0;
        display: flex;
    }

    .portfolio-photo-box > * {
        flex-grow: 1;
        flex-shrink: 1;
    }

    .portfolio-photo-box > *:nth-child(1),
    .portfolio-photo-box > *:nth-child(2),
    .portfolio-photo-box > *:nth-child(3) {
        bottom: 0;
    }
}

@media (max-width: 1439px) and (min-width: 834px) {
    .portfolio-photo-box {
        height: 240px;
    }
}

@media (max-width: 833px) {
    .portfolio-photo-box {
        height: 125px;
    }
}
