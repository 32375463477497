#contact-form {
    user-select: none;
}

#contact-form > article > .title {
    font-weight: 600;
}

#contact-form > article > form {
    margin-top: 16px;
    display: flex;
}

#contact-form > article > form > * {
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 16px;
    display: block;
}

#contact-form > article > form > *:first-child {
    margin-top: 0;
    margin-left: 0;
}

#contact-form > article > form > div > * {
    margin-top: 16px;
}

#contact-form > article > form > div > *:first-child {
    margin-top: 0;
}

#contact-form > article > form > div > .contact-form-contents {
    height: 332px;
}

#contact-form > article > .confirm {
    float: right;
    margin-top: 16px;
    padding: 0 16px;
    height: 44px;
    border-radius: 4px;
    background: #311FFF;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

#contact-form > article > .confirm:after {
    content: '';
    display: block;
    clear: both;
}

#contact-form > article > .confirm > div {
    margin-left: 12px;
    font-weight: bold;
    font-size: 14px;
}

@media (max-width: 1439px) {
    #contact-form > article > form {
        flex-direction: column;
    }

    #contact-form > article > form > * {
        margin-top: 16px;
        margin-left: 0;
    }

    #contact-form > article > form > *:first-child {
        margin-top: 0;
    }
}
